import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { CmsEntities, bindToContent } from 'client/data/models/cms';
import { getBaseBreadcrumb, getHomeBreadcrumb } from 'site-modules/shared/components/breadcrumbs/helper';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { getFullImageUrl, getStaticImageUrl } from 'client/utils/image-helpers';
import { Breadcrumbs } from 'site-modules/shared/components/breadcrumbs/breadcrumbs';

import { IMAGE_SIZES } from 'site-modules/shared/constants/editorial/editorial';

import { get } from 'lodash';
import { transformContentForFaq } from 'site-modules/shared/components/faq/utils';
import { WRAPPER_TYPES } from 'site-modules/shared/components/faq/constants';
import { Faq } from 'site-modules/shared/components/faq/faq';
import { MEDIA_PATHS } from './mediaPaths';
import { CareersImageSlider } from './careers-slider';
import './careers.scss';

const SECTION_CLASS_NAME = 'about-section mx-auto px-0';

export const CAREERS_FILE_PATH = '/about/careers-new';

const BUSINESS_ANALYTICS_DESCRIPTION =
  'Our Analytics team is a valuable business partner providing analysis and insights to each Edmunds business line, enabling fast & sound data driven decision-making at scale.';

export function CareersUI({ location, content }) {
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [hwwDescriptionText, setHwwDescriptionText] = useState(BUSINESS_ANALYTICS_DESCRIPTION);
  if (!content) {
    return null;
  }

  const { title } = content;
  const breadcrumbs = [getHomeBreadcrumb(), getBaseBreadcrumb(title)];
  const faqContent = content.child('faq');

  return (
    <main id="main-content" className="mb-0">
      <Container>
        <Breadcrumbs className="pl-0 mb-2" data={breadcrumbs} />

        {/* Join us for the ride section */}
        <Row className={classnames(SECTION_CLASS_NAME, 'flex-md-row-reverse wide-section')}>
          <Col xs={12} lg={5} xl={6} className="pr-md-0 pb-1 pb-xl-0">
            <CareersImageSlider />
          </Col>
          <Col xs={12} lg={7} xl={6} className="mb-md-1_5 mb-0">
            <h1 className="careers-heading-1 mb-md-0_5 mb-0 size-36">Join Us For The Ride</h1>
            <p className="size-16">
              Edmunds is powered by a team of bright, forward-thinking innovators. Together, we work to reimagine the
              car shopping experience for everyone.
            </p>
            <p className="size-16">
              We pride ourselves in fostering a workplace that nurtures growth, rewards effort, and supports the
              well-being of all employees.
            </p>
            <p className="size-16">Come join us &mdash; let&apos;s put the future in drive.</p>
            <Button
              tag="a"
              href="https://careers.jobscore.com/careers/edmunds"
              target="_blank"
              color="primary"
              size=""
              className="button-text-white button-block text-center"
            >
              See open positions
            </Button>
          </Col>
        </Row>
        {/* End Join us for the ride section */}

        {/* Our Values Line Break and Header Section */}

        <BlueHR />
        <Row className={classnames(SECTION_CLASS_NAME)}>
          <Col xs={12} md={12} className="mb-0_5 mb-md-1_5">
            <h2 className="section-header-blue text-center size-36">Our Values</h2>
          </Col>
        </Row>
        {/* End Our Values Facts Line Break and Header Section */}
        {/* Our Values Section */}
        <div className="mb-2 mx-auto">
          <div className="wide-section mb-2 mx-auto px-0_5">
            <p className="size-16">
              Our employees are the key to our success. To help everyone do and feel their best, we provide a space
              that&apos;s inspired, inclusive, and interconnected. When you join us, you&apos;ll be part of our culture
              of TRUST.
            </p>
          </div>
        </div>
        <div className="trust-flex-section">
          <section className="row trust-flex-container mx-auto my-0 wide-section text-center trust-text">
            <div className="col trust-flex-item m-auto p-0_5">
              <img
                className="img-responsive trust-icons mb-0_5"
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.trustImageOneUrl), IMAGE_SIZES.xs)}
                alt="Transparency"
              />
              <p className="size-16">TRANSPARENCY</p>
            </div>
            <div className="col trust-flex-item m-auto p-0_5">
              <img
                className="img-responsive trust-icons mb-0_5"
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.trustImageTwoUrl), IMAGE_SIZES.xs)}
                alt="Resourcefulness"
              />
              <p className="size-16">RESOURCEFULNESS</p>
            </div>
            <div className="col trust-flex-item m-auto p-0_5">
              <img
                className="img-responsive trust-icons mb-0_5"
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.trustImageThreeUrl), IMAGE_SIZES.xs)}
                alt="Urgency"
              />
              <p className="size-16">URGENCY</p>
            </div>
            <div className="col trust-flex-item m-auto p-0_5">
              <img
                className="img-responsive trust-icons mb-0_5"
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.trustImageFourUrl), IMAGE_SIZES.xs)}
                alt="Simplicity"
              />
              <p className="size-16">SIMPLICITY</p>
            </div>
            <div className="col trust-flex-item m-auto p-0_5">
              <img
                className="img-responsive trust-icons mb-0_5"
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.trustImageFiveUrl), IMAGE_SIZES.xs)}
                alt="Togetherness"
              />
              <p className="size-16">TOGETHERNESS</p>
            </div>
          </section>
        </div>
        {/* End Our Values section 2 */}

        {/* How We Work Line Break and Header Section */}
        <BlueHR />
        <Row className={classnames(SECTION_CLASS_NAME)}>
          <Col xs={12} md={12} className="mb-0_5 mb-md-1_5">
            <h2 className="section-header-blue text-center size-36">How We Work</h2>
          </Col>
        </Row>
        {/* End How We Work Line Break and Header Section */}
        {/* How We Work Section NEW */}
        <Row className={classnames(SECTION_CLASS_NAME, 'wide-section px-0_5')}>
          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 hww-flex-item hww-item-1">
            <HowWeWorkButton
              departmentName="Business Analytics"
              message={BUSINESS_ANALYTICS_DESCRIPTION}
              activeButtonIndex={activeButtonIndex}
              buttonIndex={0}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            className="mb-1 pr-md-1 pl-0 pl-md-0_5 hww-flex-item hww-item-2 size-16"
            id="how-we-work-description"
            aria-live="polite"
          >
            {hwwDescriptionText}
          </Col>
          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 pl-md-0_5 hww-flex-item hww-item-3">
            <HowWeWorkButton
              departmentName="Content"
              message="The Content team helps shoppers find their perfect car.
              With a focus on
              EVs and real-world testing, the team is a leading
              voice across Edmunds.com, social media and YouTube."
              activeButtonIndex={activeButtonIndex}
              buttonIndex={1}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>

          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 hww-flex-item hww-item-4">
            <HowWeWorkButton
              departmentName="Product"
              message="The Product team builds best-in-class consumer experiences
              that make car buying and selling easy. We drive exceptional value for dealers,
              OEMs and CarMax via our audience."
              activeButtonIndex={activeButtonIndex}
              buttonIndex={2}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>
          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 pl-md-0_5 hww-flex-item hww-item-5">
            <HowWeWorkButton
              departmentName="Employee Experience"
              message="The Employment Experience team's mission is to build a winning team of
              engaged employees by developing impactful talent initiatives and a compelling culture."
              activeButtonIndex={activeButtonIndex}
              buttonIndex={3}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>
          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 pl-md-0_5 hww-flex-item hww-item-6">
            <HowWeWorkButton
              departmentName="Strategy, Finance &amp; Accounting"
              message="Our Strategy, Finance and Accounting teams drive strategic decision
              making and support Edmunds' financial health through innovative solutions
              and actionable insights."
              activeButtonIndex={activeButtonIndex}
              buttonIndex={4}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>
          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 pl-md-0_5 hww-flex-item hww-item-7">
            <HowWeWorkButton
              departmentName="Marketing"
              message="The Marketing team is focused on making Edmunds a more recognizable, relevant, and reputable brand with in market shoppers and our business partner audiences."
              activeButtonIndex={activeButtonIndex}
              buttonIndex={5}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>
          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 hww-flex-item hww-item-8">
            <HowWeWorkButton
              departmentName="Dealer Operations"
              message="Our Dealer Operations team delivers exceptional customer experiences
              to dealers and consumers."
              activeButtonIndex={activeButtonIndex}
              buttonIndex={6}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>
          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 pl-md-0_5 hww-flex-item hww-item-9">
            <HowWeWorkButton
              departmentName="Sales (Dealer)"
              message="Tier 3 is our direct-to-dealer sales and operations division.
              We help our dealer partners by offering digital solutions that connect
              them with our ready-to-buy audience of car shoppers."
              activeButtonIndex={activeButtonIndex}
              buttonIndex={7}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>
          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 pl-md-0_5 hww-flex-item hww-item-10">
            <HowWeWorkButton
              departmentName="Sales (OEM)"
              message="The Tier 1 team is an indispensable marketing partner and media buy for every OEM, delivering high-value customer outcomes."
              activeButtonIndex={activeButtonIndex}
              buttonIndex={8}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>
          <Col xs={6} sm={6} md={3} className="mb-1 mb-md-1_5 pl-0 pl-md-0_5 hww-flex-item hww-item-11">
            <HowWeWorkButton
              departmentName="Technology"
              message="Our Technology team promotes innovation, learning, and productivity.
              Our platform stack is optimized to help teams work autonomously
              and to make the hardest things easy."
              activeButtonIndex={activeButtonIndex}
              buttonIndex={9}
              setActiveButtonIndex={setActiveButtonIndex}
              setHwwDescriptionText={setHwwDescriptionText}
            />
          </Col>
        </Row>
        {/* End How We Work Section NEW */}

        {/* Benefits Line Break and Header Section */}
        <BlueHR />
        <Row className={classnames(SECTION_CLASS_NAME)}>
          <Col xs={12} md={12} className="mb-1_5 mb-md-1_5">
            <h2 className="section-header-blue text-center size-36">Benefits</h2>
          </Col>
        </Row>
        {/* End Benefits Line Break and Header Section */}

        {/* Benefits body section  */}
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-1')}>
          <Col xs={12} lg={6} xl={4} className="diversity-text size-16 mb-0 pr-md-1">
            <div className="img-fluid text-center">
              <img
                className="benefits-icons"
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.benefitsImageOneUrl), IMAGE_SIZES.xs)}
                alt="Drive your health"
              />
            </div>
            <h3 className="benefits-subtitle size-20 text-center">Drive your health</h3>
            <ul className="pl-0 benefits-list">
              <li className="pb-0_5 pb-md-1">
                <strong>Medical:</strong> Comprehensive Medical, Dental, and Vision coverage &mdash; Edmunds pays
                70–100% of the medical insurance premium cost
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>FitCa$h:</strong> Reimburses up to 50% of gym fees
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>Parental Leave:</strong> Up to 4 months of paid leave
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>Flexible Time Off</strong>
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>Flexible Spending Accounts:</strong> Fund healthcare and dependent care FSAs with pre-tax money
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>Wellness:</strong> Team walking challenges, yoga and meditation sessions
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>Mental Health:</strong> Robust Employee Assistance Program and free Headspace membership
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={6} xl={4} className="diversity-text size-16 pl-md-1 mb-0">
            <div className="img-fluid text-center">
              <img
                className="benefits-icons"
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.benefitsImageTwoUrl), IMAGE_SIZES.xs)}
                alt="Steer your finances"
              />
            </div>
            <h3 className="benefits-subtitle size-20 text-center">Steer your finances</h3>
            <ul className="pl-xl-1 pl-0 benefits-list">
              <li className="pb-0_5 pb-md-1">
                <strong>401(k) Retirement Plan:</strong> Company matching at 100%, up to 6% of eligible salary with
                immediate vesting
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>Employee Stock Purchase Plan:</strong> Purchase shares of CarMax stock with a matching company
                contribution
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>Donation Gift Matching:</strong> Donations to qualified organizations are matched up to $5,000
                per year
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>CarMax Vehicle Discount:</strong> Edmunds employees and eligible family members receive a
                discount on cars purchased at CarMax
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={12} xl={4} className="diversity-text size-16 pl-md-1 mb-0">
            <div className="img-fluid text-center">
              <img
                className="benefits-icons"
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.benefitsImageThreeUrl), IMAGE_SIZES.xs)}
                alt="Fuel your growth"
              />
            </div>
            <h3 className="benefits-subtitle size-20 text-center">Fuel your growth</h3>
            <ul className="pl-xl-1 pl-0 benefits-list">
              <li className="pb-0_5 pb-md-1">
                <strong>Development Sessions:</strong> In our culture of learning, a variety of development sessions are
                offered throughout the year to build business acumen, technical skills, and other professional
                management skills; these are offered to individuals and teams on an ongoing basis, as well as through
                company-wide annual learning events
              </li>
              <li className="pb-0_5 pb-md-1">
                <strong>Tuition Reimbursement:</strong> Edmunds offers reimbursement for qualified educational
                activities that further an employee&apos;s professional development
              </li>
            </ul>
          </Col>
        </Row>

        {/* End Benefits body section */}

        {/* Diversity and Inclusion Line Break and Header Section */}
        <BlueHR />
        <Row className={classnames(SECTION_CLASS_NAME)}>
          <Col xs={12} md={12} className="mb-0_5 mb-md-1">
            <h2 className="section-header-blue text-center size-36">Diversity &amp; Inclusion</h2>
          </Col>
        </Row>
        {/* Diversity and Inclusion Line Break and Header Section */}

        {/* Diversity and Inclusion body section  */}
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-1_5 wide-section')}>
          <Col xs={12} lg={12} className="diversity-text size-16 mb-md-0 pr-lg-1_5">
            <p className="size-16">
              At Edmunds, we take pride in empowering consumers to make the very best car shopping decisions and that
              idea of empowerment extends to our employee engagement efforts, setting the tone for everything within our
              company culture.
            </p>
            <p className="size-16">
              The key to our purpose-driven culture is our passionate teammates and their focus on fostering diverse
              perspectives and experiences in the innovative work we do, in the engaging workplace we cultivate, and in
              the communities we support.
            </p>
            <p className="size-16">
              Regardless of level, function and tenure, every employee is a leader and champion of our culture of
              inclusion, belonging and giving. Our teammates are encouraged to participate in various company sponsored
              initiatives or to develop their own employee-led efforts. Some of these include our Board of TRUSTees (a
              play on our values of TRUST), Ethics and Integrity Taskforce, and our six Employee Resource Groups (listed
              below), just to name a few.
            </p>
            <ul className="ul-no-indent pl-0">
              <li>Accelerating Women @ Edmunds</li>
              <li>People of Color &amp; Allies</li>
              <li>Edmunds PRIDE</li>
              <li>Edmunds Strong</li>
              <li>EdVets</li>
              <li>Working Parents &amp; Caregivers</li>
            </ul>
          </Col>
        </Row>
        {/* End Diversity and Inclusion body section */}

        {/* Coding Principles Line Break and Header Section */}
        <BlueHR />
        <Row className={classnames(SECTION_CLASS_NAME)}>
          <Col xs={12} md={12} className="mb-1_5 mb-md-1">
            <h2 className="section-header-blue text-center size-36">Coding Principles &amp; Featured Technologies</h2>
          </Col>
        </Row>
        {/* Coding Principles Line Break and Header Section */}

        {/* Coding Principles section  */}
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-1_5 wide-section')}>
          <Col xs={12} lg={6} className="diversity-text size-16 mb-md-0 pr-lg-1_5">
            <h3 className="coding-principles-subtitle size-16 mb-0">Continuous delivery &amp; cloud first strategy</h3>
            <p className="size-16">
              We embrace continuous delivery, dev ops, and are constantly improving our processes so we can stay lean
              and innovative.
            </p>

            <h3 className="coding-principles-subtitle size-16">Scalability &amp; reliability</h3>
            <p className="size-16">
              As with any website that has millions of unique visitors, it&apos;s a necessity that we maintain a
              scalable and highly-available infrastructure with reliable services.
            </p>

            <h3 className="coding-principles-subtitle size-16">Passion for excellence</h3>
            <p className="size-16">
              Edmunds engineers get to the core of challenging technical problems, combining head and heart to deliver
              experiences using state-of-the-art technology.
            </p>

            <h3 className="coding-principles-subtitle size-16">Simplicity</h3>
            <p className="size-16">
              Edmunds technology architecture is simple to develop, maintain, replace and above all simple to explain.
              We aim to avoid the pitfalls of over engineering.
            </p>
          </Col>
          <Col xs={12} lg={6} className="diversity-text size-16 pl-lg-1_5 mb-1_5 mb-md-0">
            <h3 className="coding-principles-subtitle size-16">Data-driven decisions</h3>
            <p className="size-16">
              Data-driven decisions empower timely decision-making processes. By leveraging real time insights, we can
              make informed decisions for sustainable growth and performance optimization.
            </p>

            <h3 className="coding-principles-subtitle size-16">Be guardians of our customers&apos; data</h3>
            <p className="size-16">It is up to us to be the experts and to guide and protect our users&apos; data.</p>

            <h3 className="coding-principles-subtitle size-16">Build with the customer in mind</h3>
            <p className="size-16">
              We strive to create engaging, reliable and performant experiences by leveraging cutting edge technology,
              rapid experimentation and data-driven decision making.
            </p>

            <h3 className="coding-principles-subtitle size-16">Contributing to the tech community</h3>
            <p className="size-16">
              We also prioritize giving back to the community by open sourcing projects whenever possible.
            </p>
          </Col>
        </Row>
        {/* End Coding Principles section */}

        {/* Tools we use header section  */}
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-1_5 wide-section')}>
          <Col xs={12} md={6} className="diversity-text size-16 mb-md-0 pr-md-1">
            <h3 className="coding-principles-subtitle size-16">Tools we use:</h3>
          </Col>
        </Row>
        {/* End Tools we use header section */}

        {/* Tools we use icon section */}
        <section className="tools-flex-container mx-auto my-0 wide-section text-center">
          <div className="tools-flex-item m-auto p-0_5">
            <img
              className="img-responsive"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.toolsImageOneUrl), IMAGE_SIZES.xs)}
              alt="Java"
            />
          </div>
          <div className="tools-flex-item m-auto p-0_5">
            <img
              className="img-responsive"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.toolsImageTwoUrl), IMAGE_SIZES.xs)}
              alt="React"
            />
          </div>
          <div className="tools-flex-item m-auto p-0_5">
            <img
              className="img-responsive"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.toolsImageThreeUrl), IMAGE_SIZES.xs)}
              alt="mlflow"
            />
          </div>
          <div className="tools-flex-item m-auto p-0_5">
            <img
              className="img-responsive"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.toolsImageFourUrl), IMAGE_SIZES.xs)}
              alt="Node.js"
            />
          </div>
          <div className="tools-flex-item m-auto p-0_5">
            <img
              className="img-responsive"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.toolsImageFiveUrl), IMAGE_SIZES.xs)}
              alt="Python"
            />
          </div>
          <div className="tools-flex-item m-auto p-0_5">
            <img
              className="img-responsive"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.toolsImageSixUrl), IMAGE_SIZES.xs)}
              alt="Databricks"
            />
          </div>
          <div className="tools-flex-item m-auto p-0_5">
            <img
              className="img-responsive"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.toolsImageSevenUrl), IMAGE_SIZES.xs)}
              alt="AWS EC2"
            />
          </div>
        </section>

        <BlueHR />
        {/* Interview Process Section */}
        <Row className={classnames(SECTION_CLASS_NAME, 'wide-section')}>
          <Col xs={12} className="mb-2">
            <Faq
              {...transformContentForFaq(faqContent)}
              wrapperType={WRAPPER_TYPES.accordion}
              generateLearnMoreLinks={false}
              pathname={get(location, 'pathname', '')}
              isOpenCollapseOnLoad={false}
              visibleEntriesNum={6}
            />
          </Col>
        </Row>
        {/* End Interview Process Section */}

        {/* Tips from our Employees Line Break and Header Section */}
        <BlueHR />
        <Row className={classnames(SECTION_CLASS_NAME)}>
          <Col xs={12} md={12} className="mb-0_5 mb-md-1">
            <h2 className="section-header-blue text-center size-36">Interview Tips From Our Employees</h2>
          </Col>
        </Row>
        {/* End Tips from our Employees Line Break and Header Section */}

        {/* Tips from our Employees section  */}
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-1_4 wide-section')}>
          <Col xs={12} md={12} className="diversity-text size-16 mb-md-0 pr-md-1">
            <div className="quote-icon pos-r img-fluid">
              <img
                className=""
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.quoteIconUrl), IMAGE_SIZES.xs)}
                alt="QUOTATION"
              />
            </div>
            <p className="size-16">
              We want you to understand the goals and validate your assumptions before you start each coding exercise,
              and we want to hear your thought process as you work through potential solutions so we can help you stay
              on track. A successful interview isn&apos;t one where you produce bug-free, optimized code; our goal is to
              understand where you are today and what you&apos;ll be capable of in the future with the right support.
            </p>
          </Col>
        </Row>
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-2_5 wide-section')}>
          <div className="employee-bio pl-0_5">
            <div className="diversity-text size-16">
              <span className="employee-name-blue">Dave O.</span>
              <br />
              Director, Product Engineering
            </div>
          </div>
        </Row>
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-1_4 wide-section')}>
          <Col xs={12} md={12} className="diversity-text size-16 mb-md-0 pr-md-1">
            <div className="quote-icon pos-r img-fluid">
              <img
                className=""
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.quoteIconUrl), IMAGE_SIZES.xs)}
                alt="QUOTATION"
              />
            </div>
            <p className="size-16">
              During the development process, every engineering team is looking for bold and progressive ideas to
              incorporate into new or existing projects. Therefore, throughout your interview with the hiring team, you
              should demonstrate a passionate desire to ideate by bringing creative solutions to the problem in question
              regardless of your skills, experience, and knowledge.
            </p>
          </Col>
        </Row>
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-2_5 wide-section')}>
          <div className="employee-bio pl-0_5">
            <div className="diversity-text size-16">
              <span className="employee-name-blue">Sergei F.</span>
              <br />
              Technical Lead, Software Engineer
            </div>
          </div>
        </Row>
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-1_4 wide-section')}>
          <Col xs={12} md={12} className="diversity-text size-16 mb-md-0 pr-md-1">
            <div className="quote-icon pos-r img-fluid">
              <img
                className=""
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.quoteIconUrl), IMAGE_SIZES.xs)}
                alt="QUOTATION"
              />
            </div>
            <p className="size-16">
              In cases where your skills may not fully align with the requirements of a role, but your passion and
              potential make up for it, be prepared to showcase your ability to learn and adapt to close those gaps.
              Providing specific examples of how you have successfully attained new skills in the past can demonstrate
              your potential for growth.
            </p>
          </Col>
        </Row>
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-2_5 wide-section')}>
          <div className="employee-bio pl-0_5">
            <div className="diversity-text size-16">
              <span className="employee-name-blue">Ilana G.</span>
              <br />
              Director, Technical SEO
            </div>
          </div>
        </Row>
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-1_4 wide-section')}>
          <Col xs={12} md={12} className="diversity-text size-16 mb-md-0 pr-md-1">
            <div className="quote-icon pos-r img-fluid">
              <img
                className=""
                src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.quoteIconUrl), IMAGE_SIZES.xs)}
                alt="QUOTATION"
              />
            </div>
            <p className="size-16">
              During the interview process our teams are looking for a wide variety of examples of different projects
              you&apos;ve worked on, situations you&apos;ve been in and what your contributions have been in the roles
              you&apos;ve held. It&apos;s helpful to think through or even write out all the accomplishments you want to
              highlight during the interview process.
            </p>
          </Col>
        </Row>
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-2_5 wide-section')}>
          <div className="employee-bio pl-0_5">
            <div className="diversity-text size-16">
              <span className="employee-name-blue">Ashlee B.</span>
              <br />
              Senior Manager, Recruitment
            </div>
          </div>
        </Row>
        {/* End Tips from our Employees section */}

        {/* Open positions button */}
        <Row className={classnames(SECTION_CLASS_NAME, 'mb-1_5 wide-section')}>
          <Col xs={12} className="button-block text-center">
            <Button
              tag="a"
              href="https://careers.jobscore.com/careers/edmunds"
              target="_blank"
              color="primary"
              size=""
              className="text-white mt-md-1"
            >
              See open positions
            </Button>
          </Col>
        </Row>
        {/* End Open positions button */}

        {/* Accolades Line Break and Header Section */}
        <BlueHR />
        <Row className={classnames(SECTION_CLASS_NAME)}>
          <Col xs={12} md={12} className="mb-1_5 mb-0">
            <h2 className="section-header-blue text-center size-36">Accolades</h2>
          </Col>
        </Row>
        {/* End Accolades Line Break and Header Section */}

        {/* Accolades Section */}
        <section className="row accolades-flex-container mx-auto my-0 wide-section text-center">
          <div className="col accolades-flex-item m-auto p-0_5">
            <img
              className="img-responsive mb-0_5"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.accoladesImageThreeUrl), IMAGE_SIZES.xs)}
              alt="2023 Newsweek Americas Most Loved Workplaces"
            />
          </div>
          <div className="col accolades-flex-item m-auto p-0_5">
            <img
              className="img-responsive mb-0_5"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.accoladesImageOneUrl), IMAGE_SIZES.xs)}
              alt="2023 Built In Best Places to Work"
            />
          </div>
          <div className="col accolades-flex-item m-auto p-0_5">
            <img
              className="img-responsive mb-0_5"
              src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.accoladesImageTwoUrl), IMAGE_SIZES.xs)}
              alt="2021 Inc. Best Workplaces Honoree"
            />
          </div>
        </section>
        {/* End Accolades Section */}

        <section className="mt-2 mb-0">
          <Row className="justify-content-center flex-wrap">
            <p className="size-16">
              <a
                href="https://static.ed.edmunds-media.com/unversioned/edmunds/edmunds-careers-privacy-statement.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Edmunds Careers Privacy Statement
              </a>
            </p>
          </Row>
        </section>
      </Container>
      <Experiment name="wp-754" showDefault>
        <Recipe name="ctrl" isDefault />
        <Recipe name="chal" />
      </Experiment>
    </main>
  );
}

function BlueHR() {
  return (
    <Row className={classnames(SECTION_CLASS_NAME)}>
      <Col xs={12} md={12} className="mb-0 mt-1 mt-sm-2 text-center">
        <img
          className=""
          src={getFullImageUrl(getStaticImageUrl(MEDIA_PATHS.hrSmallBlueUrl), IMAGE_SIZES.xs)}
          alt="Blue Rectangular Spacer"
        />
      </Col>
    </Row>
  );
}

export function HowWeWorkButton({
  message,
  departmentName,
  activeButtonIndex,
  buttonIndex,
  setHwwDescriptionText,
  setActiveButtonIndex,
}) {
  function HWWHandleClick() {
    setHwwDescriptionText(message);
    setActiveButtonIndex(buttonIndex);
  }

  return (
    <Button
      color={activeButtonIndex === buttonIndex ? 'primary' : 'outline-primary'}
      className="how-we-work-button size-16"
      onClick={HWWHandleClick}
    >
      {departmentName}
    </Button>
  );
}

HowWeWorkButton.propTypes = {
  message: PropTypes.string.isRequired,
  departmentName: PropTypes.string.isRequired,
  activeButtonIndex: PropTypes.number.isRequired,
  buttonIndex: PropTypes.number.isRequired,
  setHwwDescriptionText: PropTypes.func.isRequired,
  setActiveButtonIndex: PropTypes.func.isRequired,
};

CareersUI.propTypes = {
  content: CmsEntities.Content,
  location: PropTypes.shape({}),
};

CareersUI.defaultProps = {
  content: null,
  location: {},
};

export const Careers = connectToModel(CareersUI, {
  content: bindToContent(CAREERS_FILE_PATH),
});
